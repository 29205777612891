<template>
  <v-card class="card-shadow mb-6">
    <v-card-text class="card-padding text-center">
      <v-avatar size="140">
        <v-img src="@/assets/img/team-1.jpg"></v-img>
      </v-avatar>

      <div class="pt-4 text-center">
        <h4 class="text-h3 font-weight-600 text-typo">
          <div class="mb-1">Ryan Tompson</div>
          <span class="text-h4 font-weight-light text-muted"
            >Web Developer</span
          >
        </h4>
        <div class="mt-3">
          <v-btn
            elevation="0"
            :ripple="false"
            height="38"
            min-width="38"
            width="38"
            class="font-weight-600 text-capitalize btn-ls btn-default me-2 my-2 rounded-circle"
            color="#1da1f2"
          >
            <v-icon size="14">fab fa-twitter</v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            :ripple="false"
            height="38"
            min-width="38"
            width="38"
            class="font-weight-600 text-capitalize btn-ls btn-default me-2 my-2 rounded-circle"
            color="#3b5999"
          >
            <v-icon size="14">fab fa-facebook</v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            :ripple="false"
            height="38"
            min-width="38"
            width="38"
            class="font-weight-600 text-capitalize btn-ls btn-default me-2 my-2 rounded-circle"
            color="#ea4c89"
          >
            <v-icon size="14">fab fa-dribbble</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "card-profile-avatar-big",
};
</script>
