<template>
  <v-card class="card-shadow mb-6">
    <div class="card-header-padding card-border-bottom">
      <v-row>
        <v-col
          ><v-card-title class="pa-0 text-h3 font-weight-600 text-typo"
            >Card Title</v-card-title
          ></v-col
        >
        <v-col class="text-right">
          <v-btn
            elevation="0"
            small
            min-width="45"
            :ripple="false"
            class="me-3 py-1 px-2 font-weight-600 text-capitalize btn-neutral rounded-sm"
            color="#fff"
            >Action</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <v-card-text class="card-padding">
      <p class="text-body font-size-root font-weight-light mb-5">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis non
        dolore est fuga nobis ipsum illum eligendi nemo iure repellat, soluta,
        optio minus ut reiciendis voluptates enim impedit veritatis officiis.
      </p>
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm"
        color="#5e72e4"
        >Go somewhere</v-btn
      >
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "card-simple-action",
};
</script>
