<template>
  <v-container fluid class="pt-0 px-6 pb-16" :class="{ 'bg-primary': primary }">
    <v-row no-gutters class="py-3">
      <v-col cols="12" md="6" class="d-flex align-center">
        <h2
          class="text-h2 font-weight-600"
          :class="{ 'text-white': primary, 'text-typo': !primary }"
        >
          <template v-if="$route.name === 'Dashboard'">Reseller Dashboard</template>
          <template v-else>{{ $route.name }}</template>
        </h2>
        <v-breadcrumbs>
          <v-breadcrumbs-item to="/dashboard" active-class="active-breadcrumb">
            <v-icon :color="colorActive">fas fa-home</v-icon>
          </v-breadcrumbs-item>
          <li
            class="v-breadcrumbs__divider opacity-5"
            :class="{ 'text-white': primary, 'text-muted': !primary }"
          >
            -
          </li>
          <v-breadcrumbs-item
            :class="{
              'text-white': primary,
              'text-primary': !primary,
              'font-weight-600': primary,
            }"
          >
            {{ $route.meta.groupName }}
          </v-breadcrumbs-item>
          <li
            class="v-breadcrumbs__divider opacity-5"
            :class="{ 'text-white': primary, 'text-muted': !primary }"
          >
            <!-- - -->
          </li>
          <v-breadcrumbs-item
            active-class="active-breadcrumb"
            class="no-default-hover"
            :class="{
              'text-white': primary,
              'text-muted': !primary,
              'breadcrumb-alternative': !primary,
              'font-weight-600': primary,
            }"
            :to="$route.name.toLowerCase()"
          >
            <!-- <template v-if="$route.name === 'Dashboard'"></template> -->
            <!-- <template v-else>{{ $route.name }}</template> -->
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end align-center">
        <template v-if="customRightContent">
          <slot name="rightContent"></slot>
        </template>

        <!-- <template v-else>
          <v-btn
            elevation="0"
            small
            min-width="45"
            :ripple="false"
            class="me-3 py-1 px-2 font-weight-600 text-capitalize btn-neutral rounded-sm"
            color="#fff"
            >Add new</v-btn
          >
          <v-btn
            elevation="0"
            small
            min-width="45"
            :ripple="false"
            class="font-weight-600 text-capitalize btn-neutral py-1 px-2 rounded-sm"
            color="#fff"
            >Remove</v-btn
          >
        </template> -->
      </v-col>
    </v-row>

    <slot></slot>
  </v-container>
</template>
<script>
export default {
  name: "header-top",
  props: {
    primary: Boolean,
    customRightContent: Boolean,
  },
  data() {
    return {
      colorActive: this.primary ? "#fff" : "#292B2D",
    };
  },
};
</script>
