<template>
  <v-card class="card-shadow mb-6" :class="bgColor">
    <v-card-text class="card-padding">
      <h3 class="text-h3 font-weight-600 text-white mb-5">Testimonial</h3>
      <blockquote class="blockquote text-white font-size-root mb-0 pa-0">
        <p class="mb-0 text-white">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
          posuere erat a ante.
        </p>
        <footer class="blockquote-footer text-danger font-weight-regular mt-5">
          Someone famous in
          <cite title="Source Title">Source Title</cite>
        </footer>
      </blockquote>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "card-testimonial",
  props: {
    bgColor: String,
  },
};
</script>
