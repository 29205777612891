<template>
  <v-card class="card-shadow mb-6">
    <v-card-text class="card-padding">
      <v-list class="py-0">
        <v-list-item class="pa-0">
          <v-list-item-avatar :size="74" class="my-0 me-5">
            <v-img alt="avatar" src="@/assets/img/team-2.jpg"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-typo text-h4 font-weight-600 mb-2"
              >John Snow</v-list-item-title
            >
            <span class="text-body-2 text-muted mb-2 font-weight-light"
              >Working Remotely</span
            >
            <span class="text-body">
              <span class="text-success me-1">●</span>
              Active
            </span>
          </v-list-item-content>

          <v-btn
            elevation="0"
            :ripple="false"
            min-width="41"
            class="font-weight-600 text-capitalize btn-ls btn-primary bg-primary rounded-sm py-1 px-2"
            small
            >Add
          </v-btn>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "card-profile-avatar",
};
</script>
