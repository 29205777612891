<template>
  <div>
    <header-top-dashboard primary>
      <v-row class="mt-0">
        <v-col
          v-for="(item, i) in miniCards"
          :key="i"
          cols="12"
          lg="3"
          md="6"
          class="py-0"
        >
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-stats-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-muted text-uppercase text-h5"
                  >
                    {{ item.subtitle }}
                  </div>
                  <p class="font-weight-600 text-h2 text-typo mb-0">
                    {{ item.title }}
                  </p>
                </v-col>
                <v-col class="text-right">
                  <v-avatar :color="item.gradient">
                    <v-icon size="20" class="text-white">
                      {{ item.icon }}
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>

              <p class="mt-4 mb-0 text-body font-weight-light">
                <span class="text-success me-2">3.48%</span> Since last month
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <v-row>
        <v-col lg="4" class="py-0">
          <card-image></card-image>
          <card-simple></card-simple>
          <card-profile></card-profile>
        </v-col>

        <v-col lg="4" class="py-0">
          <card-simple-border></card-simple-border>
          <card-profile-avatar></card-profile-avatar>
          <card-profile-avatar-big></card-profile-avatar-big>
          <card-get-started></card-get-started>
          <card-testimonial bg-color="bg-gradient-default"></card-testimonial>
        </v-col>

        <v-col lg="4" class="py-0">
          <card-pricing bg-color="bg-gradient-success"></card-pricing>
          <card-simple-action></card-simple-action>
          <card-bg></card-bg>
          <card-pricing></card-pricing>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import CardProfile from "./Cards/CardProfile.vue";
import CardImage from "./Cards/CardImage.vue";
import CardSimple from "./Cards/CardSimple.vue";
import CardSimpleBorder from "./Cards/CardSimpleBorder.vue";
import CardProfileAvatar from "./Cards/CardProfileAvatar.vue";
import CardProfileAvatarBig from "./Cards/CardProfileAvatarBig.vue";
import CardGetStarted from "./Cards/CardGetStarted.vue";
import CardTestimonial from "./Cards/CardTestimonial.vue";
import CardPricing from "./Cards/CardPricing.vue";
import CardSimpleAction from "./Cards/CardSimpleAction.vue";
import CardBg from "./Cards/CardBg.vue";

export default {
  name: "cards",
  components: {
    HeaderTopDashboard,
    CardProfile,
    CardImage,
    CardSimple,
    CardSimpleBorder,
    CardProfileAvatar,
    CardProfileAvatarBig,
    CardGetStarted,
    CardTestimonial,
    CardPricing,
    CardSimpleAction,
    CardBg,
  },
  data() {
    return {
      miniCards: [
        {
          subtitle: "Total Traffic",
          title: "350,897",
          gradient: "bg-gradient-danger",
          icon: "ni-active-40",
        },
        {
          subtitle: "New Users",
          title: "2,356",
          gradient: "bg-gradient-warning",
          icon: "ni-chart-pie-35",
        },
        {
          subtitle: "Sales",
          title: "924",
          gradient: "bg-gradient-success",
          icon: "ni-money-coins",
        },
        {
          subtitle: "Performance",
          title: "49,65%",
          gradient: "bg-gradient-success",
          icon: "ni-chart-bar-32",
        },
      ],
    };
  },
};
</script>
