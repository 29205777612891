<template>
  <v-card class="card-shadow mb-6">
    <v-img height="258" src="@/assets/img/img-1-1000x600.jpg"></v-img>
    <v-list-item v-for="item in 3" :key="item" class="px-4 py-2 border-bottom">
      <v-list-item-content class="py-0">
        <v-list-item-title class="text-body font-size-root">
          <template v-if="item === 1">Cras justo odio</template>
          <template v-else-if="item === 2">Dapibus ac facilisis in</template>
          <template v-else>Vestibulum at eros</template>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-card-text class="card-padding">
      <v-card-title class="pt-0 px-0 text-h3 font-weight-600 text-typo"
        >Card Title</v-card-title
      >
      <p class="text-body font-size-root font-weight-light mb-5">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis non
        dolore est fuga nobis ipsum illum eligendi nemo iure repellat, soluta,
        optio minus ut reiciendis voluptates enim impedit veritatis officiis.
      </p>
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm"
        color="#5e72e4"
        >Go somewhere</v-btn
      >
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "card-image",
};
</script>
