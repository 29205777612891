<template>
  <v-card class="card-shadow mb-6">
    <v-img
      src="@/assets/img/img-1-1000x600.jpg"
      class="white--text align-center"
      height="258"
    >
      <v-card-text>
        <v-card-title class="pt-0 px-0 text-h2 font-weight-600 text-white"
          >Card Title</v-card-title
        >
        <p class="font-size-root font-weight-light">
          This is a wider card with supporting text below as a natural lead-in
          to additional content. This content is a little bit longer.
        </p>
        <p class="font-weight-600">Last updated 3 mins ago</p>
      </v-card-text>
    </v-img>
  </v-card>
</template>
<script>
export default {
  name: "card-background",
};
</script>
