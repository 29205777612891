<template>
  <v-card class="card-shadow mb-6">
        <v-row>
        <v-col cols="12" md="10" class="py-0">
        </v-col>
        <v-col cols="12" md="2" class="py-0">

    <v-img max-width="150" class="mt-5" src="@/assets/img/residential.png"></v-img>
        </v-col>
        </v-row>


    <v-card-text class="card-padding">
      <h1 class="text-h2 text-typo font-weight-600 mb-0">
        Welcome to the Reseller Dashboard!
      </h1>
      <small class="text-muted">updated on Feb 7th at 07:23 PM</small>
      <p class="font-size-root font-weight-light text-body mt-6 mb-6">
        Want to enter the residential proxy business, but don’t really know where to start? Partner with us and resell our residential proxies with our powerful and simple-to-use API.
      </p>
      <h2
        class="text-decoration-underline text-primary text-body-2 ls-0 font-weight-600"
        >Here are some helpful and important resources:</h2
      >
</br>
      <ul>
<li>
      <a
        href="https://rayobyte-reseller.readme.io/reference"
        class="text-decoration-none text-primary text-body-2 ls-0 font-weight-600"
        target="_blank"
        >API Docs:</a
      > by using your Reseller credentials, you may tap into our Public API to build your own user workflows in addition to using this UI.
</li>
<li>
      <a
        href="https://portal.rayobyte.com/en/support/solutions/64000160269"
        class="text-decoration-none text-primary text-body-2 ls-0 font-weight-600"
        target="_blank"
        >Knowledge Base:</a
      > there are many important KB articles that help explain how to use our system, such as: geo-targetting, sticky functionality, and more.
</li>

</br>
<p>If you have any questions, please reach out to us at support@rayobyte.com - thank you for your business!</p>

</br>
</br>
</br>
</br>
</br>
</br>
 </ul>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "card-get-started",
};
</script>
